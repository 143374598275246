/*
===============================================================================
SPECIFIC DEFINITION - E-COMMERCE BASKET PAGE
-------------------------------------------------------------------------------
E-commerce shopping modifications of shopping.css
and new styles
===============================================================================
*/

.page-basket body {
    background: var(--grey-100);
}

#shopping-bag-page,
#information-page,
#offers-page,
#delivery-page,
#beauty-consultant-page,
#payment-page,
#review-page {
    margin-bottom: 15px;
}

.background-layer {
    padding-bottom: 71px;
    background-color: var(--grey-100);
}

.background-layer + .page-footer {
    padding-bottom: 81px; /* space for sticky summary box */
}

.side-column {
    text-align: right;
}

#shopping-bag-page .side-column {
    float: right;
}

.checkout-placeholder:not(:empty) {
    min-height: 100px; /* to display loading spinner and take some space even when there's no content */
    position: relative;
}

#main.w-grid.page-shopping-bag {
    z-index: auto;
    overflow: visible;
}

/*  Shopping bag
---------------------------------------------------------------------------*/

.w-notification-msgs:not(:empty) {
    margin-bottom: 20px;
}

.shopping-bag-top .right-column {
    float: right;
}

/*  Shopping bag spinner on loading
---------------------------------------------------------------------------*/

.w-content-shopping-bag .initial-spinner {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/* Quick order
---------------------------------------------------------------------------*/

.form-quick-order .with-validation-icon .k-input {
    padding-right: 1em;
}
.form-quick-order .k-widget .error-icon {
    background-color: var(--grey-100);
}

/* Empty basket section
---------------------------------------------------------------------------*/

#shopping-bag-page #empty-basket-message {
    margin-top: 35px;
}
.ui-empty-basket {
    padding-bottom: 10px;
}
.ui-empty-basket .empty-basket-icon {
    margin-bottom: 22px;
    text-align: center;
    font-size: var(--font-size-icon1);
    text-transform: uppercase;
    font-weight: bold;
}
.ui-empty-basket .empty-basket-icon::before {
    content: "\e917";
    font-family: "IconsOri";
    display: block;
    margin: -10px auto 0;
    width: 50px;
    height: 75px;
    left: 10px;
    color: var(--grey-200);
    font-size: 7rem;
    font-weight: normal;
}
.ui-empty-basket .shopping-btn {
    max-width: 326px;
    margin: 10px auto 0;
}

/* Wellness selling
---------------------------------------------------------------------------*/

.ui-wellness-selling {
    margin-top: 15px;
}

.wellness-selling-body {
    padding: 10px 10px 20px 20px;
}

.wellness-reseller-radios {
    padding-left: 20px;
}

.ui-reseller-application .k-numerictextbox,
.ui-reseller-certificate .k-numerictextbox {
    width: 100%;
    border: none;
}

.ui-reseller-application .k-datepicker,
.ui-reseller-certificate .k-datepicker {
    width: 100%;
}

.ui-reseller-application #applicationNumber,
.ui-reseller-certificate #registrationNumber,
.ui-reseller-application .k-formatted-value.k-input,
.ui-reseller-certificate .k-formatted-value.k-input {
    padding-left: 8px;
    text-align: left;
}

.wellness-confirm {
    margin-top: 10px;
}

.reseller-upload-form .k-file-extension,
.reseller-upload-form .k-file-state,
.reseller-upload-form .k-file-extension-wrapper {
    display: none;
}

.reseller-upload-form .document-preview-delete-icon:hover {
    color: var(--primary-main);
}

#wellnessSellingSuccessDialog.k-content {
    font-weight: bold;
    font-size: var(--font-size-subtitle1);
    text-align: center;
    margin: 12px 20px 0px 20px;
}

#wellnessSellingSuccessDialog .w-buttons {
    float: none;
    position: static;
    text-align: center;
    margin: 30px auto 0;
}

#wellnessSellingSuccessDialog .k-button {
    float: none;
}

/* Consultant sales info
---------------------------------------------------------------------------*/

#consultant-sales-info {
    display: flex;
    align-items: center;
    margin: 15px 0 -14px;
    padding: 5px 15px;
    border-radius: 5px 5px 0 0;
}

#consultant-sales-info:not(.hidden) + .ui-productlist-section > .checkout-section {
    border-radius: 0 0 5px 5px;
    padding-top: 20px;
}

.consultant-sales-item {
    align-self: center;
}

.consultant-sales-icon {
    margin-right: 10px;
    font-size: var(--font-size-h3);
}

.consultant-sales-text {
    margin-right: 5px;
}

/* Consumer orders
---------------------------------------------------------------------------*/

#not-approved-orders-dialog {
    display: none;
}

.consumer-orders-popup #not-approved-orders-dialog {
    display: block;
}

.ui-customer-order-detail .ui-basket-section .ui-table.basket-products {
    border-bottom: 1px solid var(--grey-300);
    margin-bottom: 15px;
}

.approved-orders .product-number {
    display: block;
    font-size: var(--font-size-caption);
    color: var(--grey-a700);
}

.approved-orders .col-consumer-name {
    line-height: 1.8;
}

/*  Recently viewed section
---------------------------------------------------------------------------*/

.w-recommendations {
    padding: 22px 0;
    background-color: var(--common-white);
    border-radius: 5px;
}

.w-recommendations .ui-tabs .menu-slider {
    border-bottom-color: var(--common-white);
}

.w-recommendations .tabs-group > .tab:not(.hidden) {
    margin-top: 20px;
}

/*  Delivery methods
---------------------------------------------------------------------------*/

form.ui-address-form > .w-buttons {
    /* need to overwrite kendo.window.css styles */
    position: relative;
    padding: 0;
}

.ui-contact-details-form .contact-details-button-wrapper {
    max-width: 330px;
    margin: 40px auto 0 auto;
}

.ui-contact-details-form .contact-details-button-wrapper .contact-details-save-button {
    width: 100%;
    margin-bottom: 10px;
}

.ui-contact-details-form .contact-details-button-wrapper .contact-details-cancel-button {
    width: 100%;
}

.ui-contact-details-form .address-header {
    font-size: var(--font-size-body1);
    font-weight: bold;
    margin-bottom: 25px;
    margin-top: 30px;
}
.ui-contact-details-form .contact-details-header {
    font-size: var(--font-size-body1);
    margin-bottom: 30px;
    text-align: center;
}

.contact-details-window .k-header {
    margin-bottom: 15px;
}

.ui-contact-details-form .field-full-name {
    padding: 10.5px 0 15px 0;
}

.contact-details-window.k-window {
    -ms-border-radius: 10px;
    border-radius: 10px;
}

.contact-details-window.k-window .k-window-content {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}

.contact-details-window.k-window .k-window-title {
    text-transform: none;
    font-size: var(--font-size-h3);
}

.contact-details-window.k-window .k-window-action {
    padding: 26px;
}

.contact-details-window.k-window .k-window-action .k-icon:before {
    font-size: var(--font-size-icon4);
}

.contact-details-window.k-window .k-window-action .k-icon:hover {
    color: #777777;
}

@media only screen and (max-width: 767px) {
    .contact-details-window.k-window .k-window-title {
        font-size: var(--font-size-h5);
    }

    .contact-details-window.k-window .k-window-content {
        padding-left: 15px;
        padding-right: 15px;
    }
}

#add-address-wnd .media {
    min-height: 50px;
}

#add-address-wnd > .k-loading-mask {
    z-index: 5003; /* .k-window > .k-widget has 5002 */
}

/*  Payment methods
---------------------------------------------------------------------------*/

.payment-methods .radio-list-label {
    margin: 0 0 15px;
}

.payment-methods .ui-radio-list .widget-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-height: 30px;
}

.payment-methods .w-radio-item:not(.selected) .ui-card-consent-desciption {
    display: none;
}

.payment-methods .ui-card-consent-label {
    padding-left: 33px;
}
/*  Messages
---------------------------------------------------------------------------*/

.ui-messages-section {
    margin-bottom: 15px;
}

.messages .heading {
    font-size: var(--font-size-body1);
    font-weight: bold;
}

.merchandising-section .heading {
    font-size: var(--font-size-h5);
    font-weight: bold;
    text-transform: uppercase;
}

.message-row {
    line-height: 2;
    margin: 15px 0 0 30px;
    position: relative;
}

.message-row .hint-underlined {
    text-decoration: underline;
}

.message-row .icons-box {
    margin-right: 10px;
    font-size: var(--font-size-h3);
    line-height: 1;
    position: absolute;
    left: -30px;
    top: -2px;
}

.message-row .discount-hint-message {
    left: -20px;
    top: -10px;
}

/*  Shopping offers
---------------------------------------------------------------------------*/
.web-offer {
    margin-bottom: 15px;
    background-color: var(--common-white);
    border-radius: 5px;
}

.product-search-box {
    z-index: 1; /* fix for ie11 */
}

.product-search-box ~ .k-icon {
    z-index: 2; /* fix for ie11 */
}

.k-list-container .k-list-scroller .highlight {
    font-weight: bold;
    background-color: yellow;
    color: var(--common-black);
}

.fee-information .original-fee {
    text-decoration: line-through;
    color: var(--grey-600);
    display: block;
}

.shipping-offer::before {
    position: absolute;
    left: 10px;
    top: 9px;
    font-size: var(--font-size-icon3);
}

.shipping-offer .link {
    margin-left: auto;
    margin-right: 1em;
    order: 3;
    white-space: nowrap;
    font-weight: bold;
    cursor: pointer;
    color: var(--common-white);
}

.shipping-offer .v-icon-arrow-right-thin:before {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--font-size-icon2);
    color: var(--purple-300);
    font-weight: bold;
}

/* Supplementary order */

.ui-supplementary-order .w-buttons {
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    #information-page,
    #offers-page,
    #delivery-page,
    #payment-page,
    #beauty-consultant-page,
    #review-page {
        margin-top: 15px;
    }

    .w-recommendations {
        background-color: var(--common-white);
    }

    .w-grid.page-shopping-bag,
    .w-grid.ui-order-confirmation-page {
        width: 100%;
    }

    .w-content-shopping-bag {
        margin-bottom: 55px;
    }

    .merchandising-section .heading {
        font-size: var(--font-size-body1);
    }
}

/* Basket actions
---------------------------------------------------------------------------*/

.empty-basket-dialog {
    padding-left: 0;
    padding-right: 0;
}

.shopping-bag-bottom {
    margin-top: 15px;
}

/* Dialog With No Title
---------------------------------------------------------------------------*/

.ui-dialog-with-no-title {
    padding: 15px;
    overflow: hidden;
}

.ui-dialog-with-no-title-content {
    font-weight: bold;
    font-size: var(--font-size-h5);
    text-align: center;
    padding: 15px 27px 18px 27px;
    max-width: 298px;
    min-height: 64px;
    max-height: 60vh;
    overflow: auto;
}

.ui-dialog-with-no-title-button {
    float: left;
    width: 172px;
    height: 45px;
}

.ui-dialog-with-no-title-button:only-child {
    width: 100%;
}

.ui-dialog-with-no-title-button:nth-child(2) {
    float: right;
}

.ui-dialog-with-no-title-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-items: center;
    grid-gap: 10px;
}

.ui-dialog-with-one-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dialog With No Title
---------------------------------------------------------------------------*/

.offers-detail {
    width: 100%;
    display: table;
}

.offers-detail-row {
    display: table-row;
}

.offers-detail-row-break {
    border-top: 1px solid var(--grey-100);
    position: absolute;
    display: block;
    width: 100%;
    margin-left: -20px;
    height: 100px;
}

.offers-detail-cell-left {
    display: table-cell;
    padding: 4px 80px 4px 0px;
}

.offers-detail-cell-right {
    display: table-cell;
    text-align: right;
}

/* Product notification dialog
---------------------------------------------------------------------------*/
.notify-popup h2 {
    text-transform: uppercase;
    margin-top: 10px;
}

.stock-notification-list-item {
    list-style-type: none;
    float: left;
    margin-right: 20px;
    min-width: 100px;
}

.stock-notification-radios {
    margin-top: 10px;
}

/* Product notification confirmation dialog
---------------------------------------------------------------------------*/
.notify-popup-confirmation h2 {
    text-align: center;
}
.notify-popup-confirmation .w-button {
    margin-top: 20px;
    text-align: center;
}

/* Payment
---------------------------------------------------------------------------*/

.payment-type-img {
    display: inline-block;
    max-width: 295px;
    max-height: 30px;
    object-fit: contain;
}

.payment-option .k-radio .invisible,
.payment-option .k-radio .radio-icon {
    top: 12px;
}

.payment-option .w-radio-item {
    padding: 15px;
}

.payment-option .widget-label {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
}

.payment-option .payment__label {
    margin-top: 10px;
    padding-right: 10px;
    line-height: initial;
}

.payment__logo {
    margin-left: auto;
    margin-top: 10px;
}

.payment-option .payment-provider-img-list .payment-type-img {
    position: static;
}

.payment-type-error {
    margin: -10px 0 0;
    color: #f00;
}

.payment-type-error::before {
    margin-right: 10px;
    font-size: var(--font-size-h3);
    position: relative;
    top: 5px;
}

.payment-provider-img-list li {
    display: inline-block;
}

.payment-provider-img-list li + li {
    margin-left: 5px;
}

.payment-provider-img-list .payment-type-img {
    float: none;
}

.payment-type-select {
    margin-top: 10px;
}

.payment-type-select .payment-type-img {
    margin-left: 5px;
    max-width: none;
    max-height: 45px;
}

.payment-provider-select {
    float: left;
}

.payment-type-select .payment-provider-img-list {
    float: none;
    margin-left: 10px;
}

.payment-type-select .payment-provider-img-list li {
    margin-left: 0;
    line-height: 45px;
}

.payment-type-blocked .w-radio-item .widget-label {
    color: var(--grey-300);
}

.payment-type-blocked .w-radio-item .title-icon {
    display: none;
}

/* Donation
-------------------------------------------------------------------------------*/

.ui-donation {
    padding: 7px 20px 20px; /* 7px = 20 - 13 (checkbox label top padding) */
    background-color: var(--common-white);
    border-radius: 5px;
}

.ui-donation p {
    margin: 0;
}

/* Order review
-------------------------------------------------------------------------------*/

#review-page .ui-order-review-box,
#review-page .ui-order-summary,
#review-page .ui-productlist-section {
    box-shadow: 0px 3px 8px 0px rgba(51, 51, 51, 0.1);
}

.ui-order-review-box {
    margin-bottom: 15px;
}

.ui-order-review-products {
    margin-bottom: 15px;
}

.ui-place-order-error-summary {
    color: var(--error-main);
    margin-bottom: 15px;
}

.ui-place-order-error-summary .widget-label {
    color: var(--error-main);
}

.ui-place-order-error-summary p {
    margin: 0;
}

.ui-payment-redirect-message {
    text-align: center;
}

#review-page [data-navigation-id] {
    cursor: pointer;
}

.ui-order-review-delivery .delivery-detail {
    margin-bottom: 14px;
}

.ui-order-review-delivery .delivery-detail p,
.ui-order-review-delivery .estimated-date {
    margin: 0;
}

.ui-order-review-payment .payment-type-img {
    float: right;
}

.ui-order-review-payment .w-order-status {
    margin: 5px 0 10px;
}

.ui-order-review-payment .w-buttons {
    margin-left: 10px;
}

.ui-order-review-payment-content-wrapper {
    display: block;
}

.ui-order-review-payment-content-wrapper .ui-order-review-payment-content {
    min-height: 30px;
}

.ui-order-review-billing-address .contact-phone {
    border-top: 2px solid var(--grey-100);
    padding-top: 5px;
    margin-top: 10px;
    color: var(--grey-600);
}

.clear-float {
    clear: both;
}

.ui-pay-pending .w-buttons {
    position: relative;
}

.ui-pay-pending .w-buttons .k-button {
    width: auto;
}

@media only screen and (max-width: 479px) {
    .ui-pay-pending .w-buttons {
        float: none;
    }

    .ui-pay-pending .w-buttons .k-button {
        margin-left: 0;
        width: 100%;
    }

    .ui-pay-pending .w-buttons .k-button:first-child {
        margin-bottom: 15px;
    }
}

/* Cancel order confirm or failed popups
-------------------------------------------------------------------------------*/
.ui-cancel-confirm.k-window-content > .w-buttons {
    position: relative;
    padding: 0;
}

.ui-cancel-confirm {
    text-align: center;
    margin: 0;
}

.ui-cancel-confirm .w-buttons {
    padding-top: 15px;
}

/* Send mail for order popup dialog
-------------------------------------------------------------------------------*/

.ui-send-email > .w-buttons {
    position: relative;
    padding: 0;
}

/* Order confirmation
-------------------------------------------------------------------------------*/

.ui-order-confirmation-page .w-text {
    margin-top: 30px;
    text-align: center;
}

.ui-order-confirmation-page .heading,
.ui-order-confirmation-page .h4,
.ui-order-confirmation-page .h3,
.ui-order-detail-page .heading,
.ui-order-detail-page .h4,
.ui-order-detail-page .h3 {
    font-weight: bold;
}

.ui-order-confirmation-page .thank-you {
    margin-top: 30px;
    padding: 0 15px;
}

.ui-order-confirmation-page .action-buttons {
    margin: 60px 5px 9px; /* space buffer for .k-button box-shadow so it is not cut due to overflow: hidden applied on parents */
}

.ui-order-confirmation-business-status .heading {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 0;
}

.ui-order-confirmation-page .lowercase {
    text-transform: none;
}

.ui-order-confirmation-business-status .customer-info {
    display: block;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-order-confirmation-need-help {
    padding: 30px 20px 10px;
    text-align: right;
}

.ui-order-confirmation-need-help .k-button {
    margin-bottom: 15px;
}

.ui-order-tracking .ui-table {
    min-width: 300px;
}

.ui-order-confirmation-error {
    color: #fb0c0c;
}

.cancel-order-help {
    cursor: default;
    position: relative;
    top: 5px;
    left: 5px;
    vertical-align: baseline;
}

.w-order-status-red {
    color: var(--error-main);
}

.w-order-status-green {
    color: var(--primary-main);
}

.w-order-status-yellow {
    color: #ffc72c;
}

/* Order Detail
-------------------------------------------------------------------------------*/

.ui-order-detail-page h1 {
    text-align: center;
}

.ui-order-detail-page .manage-buttons {
    margin-bottom: 15px;
}

.ui-order-detail-page .manage-buttons .k-button {
    margin: 0 0 10px 10px;
}

.ui-order-detail-page .ui-order-summary {
    border: 1px solid var(--grey-300);
    border-radius: 0px;
}

.ui-order-detail-page .ui-order-summary .heading {
    padding: 10px 0 10px 0;
}

.ui-order-detail-page .ui-productlist-section .header,
.ui-order-detail-page .ui-productlist-section .row {
    border-bottom: 1px solid var(--grey-300);
}

.ui-order-details-actions {
    margin: 15px 0;
}

/* Order widgets
-------------------------------------------------------------------------------*/

.ui-order-widget-header {
    margin: 15px 0;
    padding-right: 3px; /* space buffer for .k-button box-shadow so it is not cut due to overflow: hidden applied on parents */
}

/* Team Orders
-------------------------------------------------------------------------------*/

#AddTeamMemberWindow .k-textbox {
    padding-right: 30px;
}

.w-team-order-buttons {
    margin: 15px 0;
}

.k-window-content .ui-select-customer .form-actions {
    margin-top: 10px;
    text-align: right;
}

.ui-team-order .leader {
    background: #ffffe0;
}

.close-window {
    position: absolute;
    top: 2px;
    right: 5px;
    cursor: pointer;
    font-size: var(--font-size-icon1);
}

/* Customer orders
-------------------------------------------------------------------------------*/

.ui-customer-orders .k-grid-toolbar .status-filter {
    float: right;
    margin: 3px;
}

.ui-customer-orders .k-grid-toolbar .status-filter .k-dropdown {
    display: inline-block;
    vertical-align: middle;
}

/* Customer orders detail */

.ui-customer-order-detail {
    padding-bottom: 10px;
}

/* Manage subscriptions
-------------------------------------------------------------------------------*/

.customer-subscription {
    margin-bottom: 10px;
}

.customer-details {
    margin-top: 0.5em;
}

.subscriptions-total {
    background-color: var(--grey-100);
    font-size: var(--font-size-subtitle1);
    line-height: 2.65;
    padding: 0 1em;
    margin-top: 1em;
}

.subscriptions-total .heading {
    text-transform: uppercase;
    margin: 0;
    float: left;
}

.subscriptions-total .stats {
    float: right;
    margin: 0;
}

.ui-subscription-customer-box {
    position: relative; /* for loading overlay */
}

.checkbox-heading {
    float: right;
    margin: 16px 0 0;
}

.subscription-removal-confirmation-button {
    width: auto;
}

/*  Subscriptions
-------------------------------------------------------------------------------*/

.w-new-subscription .k-summary {
    margin-bottom: 25px;
}

.w-new-subscription .ui-promo-slider {
    padding-bottom: 30px;
    position: relative;
}

.w-new-subscription .ui-promo-slider .rsNav {
    bottom: 0;
}

.w-new-subscription .ui-promo-slider .rsArrow {
    width: 40px;
}

.w-new-subscription .ui-promo-slider .rsArrowIcn {
    overflow: hidden;
}

.w-new-subscription .subscription-type-list {
    list-style: none;
    font-size: var(--font-size-subtitle1);
}

.w-new-subscription .subscription-type-list li {
    padding: 1em 0;
    cursor: pointer;
    border-top: 1px solid var(--grey-300);
}

.w-new-subscription .subscription-type-list li:last-child {
    border-bottom: 1px solid var(--grey-300);
}

.w-new-subscription .subscription-type-list li:hover {
    color: var(--text-hover);
}

.w-new-subscription .subscription-type-list li::after {
    font-size: var(--font-size-icon5);
    float: right;
}

.w-new-subscription .subscription-package-image {
    margin-right: 15px;
    width: 50px;
    vertical-align: middle;
}

.w-new-subscription .subscription-package-image.icon-missing {
    font-size: 5rem;
    padding: 8px;
    color: var(--grey-200);
    line-height: 1;
}

.w-new-subscription .w-package-container {
    list-style: none;
    white-space: nowrap;
}

.w-new-subscription .w-package-container {
    margin-bottom: 15px;
}

.w-new-subscription .w-package-info {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 85px); /* 85px = width + margin of .subscription-package-image */
}

.w-new-subscription .subscription-package-name {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.w-new-subscription .btn-subscribe-package {
    display: block;
}

.w-new-customer {
    margin-bottom: 25px;
}

/* region general styles for grid / tables */
.size1,
.size2 {
    display: none;
    font-size: var(--font-size-caption);
}

.size1 .subvalue,
.size2 .subvalue {
    font-weight: bold;
}

.size1 .subtitle,
.size2 .subtitle {
    text-transform: uppercase;
}

/*  VIP Ordering
-------------------------------------------------------------------------------*/

.vip-order .w-control {
    margin-bottom: 15px;
}

/*  Customer registration
-------------------------------------------------------------------------------*/
.login-name {
    font-weight: bold;
}

.w-control.no-password-confirmation {
    margin: 0 0 15px;
}

/*  Telephone verification
-------------------------------------------------------------------------------*/
#telephoneVerificationDialog {
    padding: 3rem;
}

.verification-section .popup-heading {
    text-align: center;
    font-size: var(--font-size-h5);
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.verification-section p {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-size: var(--font-size-body1);
    text-align: center;
}

.verification-section .verification-notes,
.verification-section .verification-notes p {
    text-align: center;
    font-size: var(--font-size-overline);
}

.verification-section .verification-notes .verification-resend-link {
    color: #a9c23f;
    cursor: pointer;
    text-decoration: underline;
}

.verification-section .verification-notes .verification-resend-link {
    text-decoration: none;
}

.verification-section .verification-dialog-input-container {
    text-align: center;
    margin-bottom: 2rem;
}

.verification-section span.verification-code-input {
    border-bottom: 2px solid var(--grey-300);
    -ms-border-radius: 0;
    border-radius: 0;
    width: 5rem;
    max-width: 12%;
    display: inline-block;
    margin: 1rem;
}

.verification-section input.verification-code-input {
    font-size: var(--font-size-h5);
    font-weight: 700;
    text-align: center;
    padding: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}

.verification-section .incorrect-code span.verification-code-input {
    border-bottom: 2px solid #dc868f;
}

.verification-section .error {
    text-align: center;
    padding-bottom: 0;
}

.verification-section .error .v-icon-red {
    font-size: var(--font-size-icon2);
    vertical-align: middle;
    padding-right: 0.5rem;
}

.verification-section .successful-step {
    text-align: center;
}

.verification-section .successful-step .v-icon-check-round {
    color: #a9c23f;
    font-size: 13rem;
}

/*  Customer orders
-------------------------------------------------------------------------------*/

.product-list-row-updating {
    opacity: 0.4;
}
.ui-customer-order-submit-buttons {
    margin-top: 20px;
    padding-right: 5px; /* space buffer for .k-button box-shadow so it is not cut due to overflow: hidden applied on a wrapper */
}

.customer-order-edit-total {
    padding-top: 10px;
}

/* #region page headers */
.shopping-bag-header {
    text-transform: uppercase;
}
/* #endregion */

/* #region print styles */

@media print {
    .ui-order-confirmation-business-status,
    .ui-order-confirmation-need-help,
    .w-buttons,
    .page-footer,
    .page-header {
        display: none;
    }
}
/* #endregion print styles */

/* #region Breakpoints - 1, 2 */
@media only screen and (max-width: 767px) {
    /* Top of shopping bag  */

    #pending-order-section {
        display: none;
    }

    #shopping-bag-navigation {
        margin: 0 0 15px;
    }

    /* title */
    .shopping-bag-header {
        display: none;
    }

    /* Order confirmation */

    .ui-order-confirmation-need-help {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
    }

    .ui-order-confirmation-page .action-buttons {
        margin-top: 15px;
    }

    .ui-order-confirmation-page .action-buttons .k-button {
        width: 100%;
        margin-bottom: 10px;
    }

    /* Order review */
    .w-order-status .w-buttons {
        float: none;
        margin-left: 0;
    }

    .w-order-status .k-button {
        width: 100%;
        margin: 10px 0 0;
    }
    /* Customer orders */

    .new-customer-order-button {
        width: auto;
    }

    /* Manage subscriptions */

    .customer-details .w-control {
        width: 100%;
    }

    .ui-basket-section .subscription-step {
        display: block;
    }

    .hide-on-small {
        display: none;
    }

    .ui-basket-section .row-discount .col-image-bundle {
        display: table-cell;
    }

    .ui-basket-section .flags .flag {
        max-width: 120px;
    }

    .table-products .no-spinners .k-numeric-wrap,
    .table-products .k-numeric-wrap {
        padding: 2px;
    }

    .table-products .k-numeric-wrap .k-input {
        height: 30px;
        min-height: 30px;
    }

    .table-products .k-numeric-wrap .k-select {
        display: none;
    }

    /* Order detail */
    .ui-order-detail-page .w-buttons {
        width: 100%;
    }

    .ui-order-detail-page .w-buttons .k-button {
        width: calc(100% - 10px);
        float: none;
    }

    /* Send mail for order popup dialog */
    .ui-send-email .w-buttons {
        width: 100%;
    }

    .ui-send-email .w-buttons .k-button {
        margin: 0 0 10px 0;
    }

    /* Delivery */
    .ui-address-form .w-buttons {
        /* need to overwrite kendo.window.css styles */
        float: none;
        margin-bottom: 10px;
    }

    .ui-address-form .w-buttons .k-button {
        margin-left: 0;
        float: none;
    }

    .ui-address-form .w-buttons .k-button:not(:first-child) {
        margin-top: 10px;
    }

    /* Dialog With No Title */

    .ui-dialog-with-no-title-content {
        font-size: var(--font-size-body1);
        min-height: 50px;
        padding: 15px 6px 14px 6px;
    }

    .ui-dialog-with-no-title-button {
        width: 150px;
    }
}
/* #endregion Breakpoints - 1, 2 */

/* #region Breakpoints 1, 2, 3 */
@media only screen and (max-width: 919px) {
    .product-name-text {
        font-weight: bold;
    }

    .ui-productlist-section .col-heading-full {
        font-size: var(--font-size-body1);
    }
}
/* #endregion Breakpoints - 1, 2, 3 */

/* #region Breakpoint 1 */
@media only screen and (max-width: 479px) {
    /* Manage subscriptions */

    .subscriptions-total .heading,
    .subscriptions-total .stats {
        float: none;
    }

    /* Subscriptions */

    .w-new-subscription .w-buttons .k-button {
        margin: 0 0 10px;
    }

    .w-new-subscription .w-buttons .k-button:last-child {
        margin-bottom: 0;
    }

    /* General grid size */
    .size1,
    .size2 {
        display: block;
    }
    .k-pdf-export .size1,
    .k-pdf-export .size2 {
        display: none;
    }

    /* Payment options */
    .payment-provider-select {
        width: 100%;
        float: none;
    }

    /* show only one payment provider logo */
    .payment-provider-img-list {
        max-width: 70px;
        overflow-x: hidden;
    }

    .payment-provider-img-list li {
        width: 70px;
        text-align: right;
    }
}
/* #endregion Breakpoint 1 */

/* #region Breakpoint 2 */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    /* General grid size */
    .size2 {
        display: block;
    }
    .k-pdf-export .size2 {
        display: none;
    }
}
/* #endregion Breakpoint 2 */

/* #region Breakpoint 3 */
@media only screen and (min-width: 768px) and (max-width: 919px) {
    /* Top of shopping bag  */

    #pending-order-section .ui-action-box .body {
        padding: 10px 10px 10px 20px;
    }
}
/* #endregion Breakpoint 3 */

/* #region Breakpoint 3+ */
@media only screen and (min-width: 768px) {
    .w-new-subscription .ui-promo-slider {
        padding: 0 40px 30px;
        margin: 0 -35px;
    }

    .ui-order-confirmation-page .action-buttons .k-button:last-child {
        margin-left: 10px;
    }

    #shopping-bag-page #empty-basket-message {
        margin-top: 15px;
        text-align: left;
    }

    /* PAYMENT */

    .payment-option .w-radio-item {
        padding: 30px;
    }
}
/* #endregion Breakpoint 3+ */

/* #region Breakpoint 4+ */
@media only screen and (min-width: 920px) {
    /* Top of shopping bag  */
    .shopping-bag-top {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    #pending-order-section .ui-action-box .body {
        padding: 10px 10px 10px 20px;
    }

    #pending-order-section .body .msg {
        padding-bottom: 0;
    }

    /*  Messages */

    .messages {
        padding-top: 15px;
        padding-left: 3px;
    }
}
/* #endregion Breakpoint 4+ */

/* #region Slider height fix */
/* TODO: remove after slider is updated / update after all product boxes are replaced by the DL module
   Setting "min-height" is not really an ideal solution because a product image is stretched to fill the most space possible. */

#offers-page .xui-product-box .w-info {
    position: relative;
}

.ui-shopping-offer .xui-product-box .image {
    min-height: 220px;
}

#offers-page .ui-shopping-offer .xui-product-box .image {
    min-height: initial;
}

@media only screen and (min-width: 920px) and (max-width: 1024px) {
    .ui-shopping-offer .rsContent .xui-product-box .image {
        min-height: 193px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 919px) {
    .ui-shopping-offer .rsContent .xui-product-box .image {
        min-height: 162px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .ui-shopping-offer .rsContent .xui-product-box .image {
        min-height: 127px;
    }

    #export-excel,
    #print-order,
    .k-grid-excel,
    .k-grid-pdf {
        display: none;
    }
}

@media only screen and (max-width: 479px) {
    .ui-shopping-offer .rsContent .xui-product-box .image {
        min-height: 117px;
    }

    #export-excel,
    #print-order,
    .k-grid-excel,
    .k-grid-pdf {
        display: none;
    }
}

/* #endregion Slider height fix */

/*
    Breakpoint 5
*/
@media only screen and (min-width: 1025px) {
    #pending-order-section .ui-action-box .body {
        padding: 10px 10px 10px 20px;
    }
}

.view-courier-status-button {
    width: 100%;
    margin-top: 20px;
}

.parcel-tracking-number {
    width: 100%;
    margin-top: 20px;
}

.created-by-tooltip {
    white-space: nowrap;
}

/* styles for very small (& old) mobile devices */
@media only screen and (max-width: 359px) {
    .ui-dialog-with-no-title-content {
        width: auto;
    }
    .ui-dialog-with-no-title-button {
        float: none;
        margin: 0 auto 10px;
    }
}

/* SUPER APP shopping bag fix */
.super-app-basket-fix .page-shopping-bag {
    height: 100vh !important;
    padding-bottom: 80px;
}
.super-app-basket-fix .summary-box-fix {
    position: -webkit-sticky !important;
    z-index: 100 !important;
}
.super-app-basket-fix .ui-order-summary {
    bottom: 0px !important;
}
